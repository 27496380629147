var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12 text-right"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-11"}),_c('div',{staticClass:"col-md-1"},[_c('div',{staticClass:"btn-group float-right"},[(_vm.$store.getters.can('tif.contratos.agregarTarifario'))?_c('button',{staticClass:"btn btn-sm bg-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal_add_tarifario"}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e()])])])])]),(_vm.$parent.form)?_c('table',{staticClass:"table table-bordered table-striped table-hover text-nowrap table-sm"},[_vm._m(0),_c('tbody',_vm._l((_vm.$parent.form.contratos_tarifarios),function(tarifario,indice){return _c('tr',{key:indice},[_c('td',{staticClass:"text-center font-weight-bold"},[_vm._v(_vm._s(tarifario.id))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(tarifario.nombre))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(tarifario.observaciones))]),_c('td',{staticClass:"text-center font-weight-bold"},[_vm._v(_vm._s(tarifario.nBloque))]),_c('td')])}),0)]):_vm._e(),_c('div',{staticClass:"modal fade",attrs:{"id":"modal_add_tarifario"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Agregar Tarifario")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',[_vm._v("Bloques")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.form.bloque_id.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Seleccione...","label":"nombre","multiple":"","options":_vm.listasForms.bloques},on:{"input":function($event){return _vm.selectBloques()}},model:{value:(_vm.bloque),callback:function ($$v) {_vm.bloque=$$v},expression:"bloque"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Contrato Id")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.form.id),expression:"$parent.form.id"}],staticClass:"form-control form-control-sm font-weight-bold",attrs:{"type":"number","disabled":""},domProps:{"value":(_vm.$parent.form.id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.form, "id", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-9"},[_c('label',[_vm._v("Tarifario")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tif_tarifario_id),expression:"form.tif_tarifario_id"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tif_tarifario_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tif_tarifario_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.validarRepetidos]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.tarifarios),function(tar){return _c('option',{key:tar.id,domProps:{"value":tar.id}},[_vm._v(" "+_vm._s(tar.nombre)+" ")])})],2)])])]),_c('div',{staticClass:"modal-footer justify-content-between"},[(
              _vm.$store.getters.can('tif.tarifarios.edit') && !_vm.$v.form.$invalid
            )?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("#")]),_c('th',{staticClass:"text-center"},[_vm._v("Nombre")]),_c('th',{staticClass:"text-center"},[_vm._v("Observaciones")]),_c('th',{staticClass:"text-center"},[_vm._v("Bloque")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"120px"}},[_vm._v("Acción")])])])
}]

export { render, staticRenderFns }