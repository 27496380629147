<template>
  <div class="row">
    <div class="form-group col-md-12 text-right">
      <div class="card-header pt-2 pb-2">
        <div class="row">
          <div class="col-md-11"></div>
          <div class="col-md-1">
            <div class="btn-group float-right">
              <button
                type="button"
                class="btn btn-sm bg-primary"
                data-toggle="modal"
                data-target="#modal_add_tarifario"
                v-if="$store.getters.can('tif.contratos.agregarTarifario')"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table
      class="table table-bordered table-striped table-hover text-nowrap table-sm"
      v-if="$parent.form"
    >
      <thead class="thead-dark">
        <tr>
          <th class="text-center">#</th>
          <th class="text-center">Nombre</th>
          <th class="text-center">Observaciones</th>
          <th class="text-center">Bloque</th>
          <th class="text-center" style="width: 120px">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(tarifario, indice) in $parent.form.contratos_tarifarios"
          :key="indice"
        >
          <td class="text-center font-weight-bold">{{ tarifario.id }}</td>
          <td class="text-center">{{ tarifario.nombre }}</td>
          <td class="text-center">{{ tarifario.observaciones }}</td>
          <td class="text-center font-weight-bold">{{ tarifario.nBloque }}</td>
          <td></td>
          <!-- <td class="text-right">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-sm bg-info"
                data-toggle="modal"
                data-target="#modal_tarifa_tarifario_historico"
                @click="getDatosHistorico(tarifa)"
              >
                <i class="fas fa-history"></i>
              </button>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                data-toggle="modal"
                data-target="#modal_tarifa_tarifario"
                @click="getDatosModal(tarifa)"
                v-if="$store.getters.can('tif.tarifarios.crearTarifa')"
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-danger"
                @click="destroy(tarifa)"
                v-if="
                  $store.getters.can('tif.tarifarios.eliminarTarifa') &&
                  $parent.estado == 1
                "
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </td> -->
        </tr>
      </tbody>
    </table>

    <div class="modal fade" id="modal_add_tarifario">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Agregar Tarifario</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-12">
                <label>Bloques</label>
                <v-select
                  :class="[
                    $v.form.bloque_id.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="bloque"
                  placeholder="Seleccione..."
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  multiple
                  :options="listasForms.bloques"
                  @input="selectBloques()"
                ></v-select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-3">
                <label for="nombre">Contrato Id</label>
                <input
                  type="number"
                  class="form-control form-control-sm font-weight-bold"
                  v-model="$parent.form.id"
                  disabled
                />
              </div>
              <div class="form-group col-md-9">
                <label>Tarifario</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tif_tarifario_id"
                  :class="
                    $v.form.tif_tarifario_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @change="validarRepetidos"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tar in tarifarios"
                    :key="tar.id"
                    :value="tar.id"
                  >
                    {{ tar.nombre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-if="
                $store.getters.can('tif.tarifarios.edit') && !$v.form.$invalid
              "
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import vSelect from "vue-select";
export default {
  name: "Tarifarios",
  components: {
    vSelect,
  },
  data() {
    return {
      accion: 0,
      form: {
        bloque_id: null,
        tif_tarifario_id: null,
        tif_contrato_id: null,
      },
      bloque: null,
      listasForms: {
        bloques: [],
      },
      tarifarios: {},
    };
  },
  validations() {
    return {
      form: {
        bloque_id: {
          required,
        },
        tif_tarifario_id: {
          required,
        },
      },
    };
  },
  methods: {
    init() {
      this.form.tif_contrato_id = this.$parent.form.id;

      axios.get("/api/tif/tarifarios/lista").then((response) => {
        this.tarifarios = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    async validarFechas() {
      // Se valida que las fechas estén dentro de la vigencia del contrato
      await this.validaVigenciaContrato();
    },

    async validaVigenciaContrato() {
      const inicio_contrato = this.$parent.form.fecha_ini;
      const fin_contrato = this.$parent.form.fecha_fin;
      const fechaAnterior = moment(this.tarifaModal.fecha_ini).format(
        "YYYY-MM-DD"
      );

      if (this.form.fecha_ini) {
        if (
          !this.validaFechas(inicio_contrato, this.form.fecha_ini) ||
          !this.validaFechas(this.form.fecha_ini, fin_contrato)
        ) {
          this.form.fecha_ini = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa se encuentra fuera de las fechas estipuladas en el contrato...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
        if (fechaAnterior >= this.form.fecha_ini && this.accion == 2) {
          {
            this.form.fecha_ini = null;
            this.$swal({
              icon: "error",
              title: `La Fecha Inicio no puede ser menor a la fecha inicio del historico...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        }
      }
      if (this.form.fecha_fin) {
        if (
          !this.validaFechas(inicio_contrato, this.form.fecha_fin) ||
          !this.validaFechas(this.form.fecha_fin, fin_contrato)
        ) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Fin de la Tarifa se encuentra fuera de las fechas estipuladas en el contrato...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    selectBloques() {
      this.form.bloque_id = null;
      if (this.bloque) {
        this.form.bloque_id = this.bloque.map((b) => b.id);
        this.validarRepetidos();
      }
    },

    validarRepetidos() {
      for (let i = 0; i < this.form.bloque_id.length; i++) {
        if (
          this.form.bloque_id[i] &&
          this.form.tif_contrato_id &&
          this.form.tif_tarifario_id
        ) {
          const tarifarios = this.$parent.form.contratos_tarifarios;
          let flag = false;
          tarifarios.forEach((tar) => {
            if (
              this.form.bloque_id[i] == tar.pivot.bloque_id &&
              this.form.tif_contrato_id == tar.pivot.tif_contrato_id &&
              this.form.tif_tarifario_id == tar.pivot.tif_tarifario_id
            ) {
              flag = true;
            }
          });
          if (flag) {
            this.form.bloque_id.splice(i, 1);
            this.bloque.splice(i, 1);
            this.$swal({
              icon: "error",
              title: `Ya existe asignado un tarifario con los parámetros seleccionados...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        }
      }
    },

    save() {
      this.$parent.cargando = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/tif/contratos/assingOrRevokeTarifario",
          data: this.form,
        })
          .then((response) => {
            this.$parent.cargando = false;
            this.$parent.form.contratos_tarifarios =
              response.data.contrato.contratos_tarifarios;
            this.$refs.closeModal.click();

            this.$swal({
              icon: "success",
              title: "El tarifario se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },
  mounted() {
    this.init();
    this.getBloques();
  },
};
</script>
